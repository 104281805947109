import styled, { css } from "styled-components";
import StructManageTab from "../../tree/structManageTab";
import ModelUtil from "../../util/modelUtil";
import SignatureUtil from "../../util/signatureUtil";
import TreeUtil from "../../../../../../common/component/tree/treeUtil";
import Styles from "../../../../../../def/design/styles";
import { useEffect, useState } from "react";
import assert from "assert";

namespace RelationTab {

    type LocalSate = {
        filter: string;
    }

    export const Component = (props: {
        ownerState: StructManageTab.LocalState;
        invalidate: () => void;
    }) => {

        const [ls, setLs] = useState<LocalSate>({
            filter: ''
        });
        const invalidate = () => { setLs({ ...ls }) };

        // 別の要素を選択した際にフィルターをリセットする。
        useEffect(() => {
            ls.filter = '';
            invalidate();
        }, [props.ownerState.focus?.node]);

        const focus = props.ownerState.focus;
        if (focus == null) return <></>;

        const setCriteria = (node: TreeUtil.ElementNode) => {
            // props.ownerState.criteriaNode = node;
            if (props.ownerState.focus != null) {
                props.ownerState.focus.node = node;
            }
            props.invalidate();
        }

        const scopeRecordJsxes: JSX.Element[] = [];
        focus.scope.levels.forEach(level => {
            scopeRecordJsxes.push(<_OwnerRecord key={scopeRecordJsxes.length} onClick={() => { setCriteria(level.owner) }}>
                <Styles._Span color="#0011c745">{`${'.'.repeat(level.aboveLevel)}-`}</Styles._Span>
                {/* <Styles._Span color="#0011c7">{`>`}</Styles._Span> */}
                {/* <Styles._Span color="#0011c7">{`Lv.${level.aboveLevel} `}</Styles._Span> */}
                {level.labelJsx}
            </_OwnerRecord>);

            level.nodes.forEach(node => {
                const wrap = node.data as ModelUtil.WrapElement;
                const idName = wrap.data.id as string;
                assert(idName != undefined, `wrap.dataにidプロパティが存在しない。[${JSON.stringify(wrap.data).trim()}]`);
                // フィルターに一致しない場合は表示しない。
                if (ls.filter !== '' && idName.indexOf(ls.filter) === -1) return 1;
                switch (wrap.type) {
                    case 'state': {
                        scopeRecordJsxes.push(<_ItemRecord key={scopeRecordJsxes.length} onClick={() => { setCriteria(node) }}>
                            {SignatureUtil.getStateName(wrap.data)}
                        </_ItemRecord>);
                    } break;
                    case 'variable': {
                        scopeRecordJsxes.push(<_ItemRecord key={scopeRecordJsxes.length} onClick={() => { setCriteria(node) }}>
                            {SignatureUtil.getVariableName(wrap.data)}
                        </_ItemRecord>);
                    } break;
                    case 'func': {
                        scopeRecordJsxes.push(<_ItemRecord key={scopeRecordJsxes.length} onClick={() => { setCriteria(node) }}>
                            {SignatureUtil.getFunctionName(wrap.data)}
                        </_ItemRecord>);
                    } break;
                    case 'style': {
                        scopeRecordJsxes.push(<_ItemRecord key={scopeRecordJsxes.length} onClick={() => { setCriteria(node) }}>
                            {SignatureUtil.getStyleName(wrap.data)}
                        </_ItemRecord>);
                    } break;
                    case 'compdef': {
                        scopeRecordJsxes.push(<_ItemRecord key={scopeRecordJsxes.length} onClick={() => { setCriteria(node) }}>
                            {SignatureUtil.getCompName(wrap.data)}
                        </_ItemRecord>);
                    } break;
                }
            });
        });

        const referRecordJsxes: JSX.Element[] = [];
        if (focus.refarence.length > 0) {
            focus.refarence.forEach(r => {
                let jsx: JSX.Element = <></>;
                r.addrs.forEach((addr, i) => {
                    jsx = <>{jsx}
                        <Styles._Span color={'#ffffff88'}>
                            {i === 0 ? ':' : ''}
                            {addr}
                        </Styles._Span>
                        {i < r.addrs.length - 1 ? <>{'>'}</> : '.'}
                    </>;
                });
                referRecordJsxes.push(<_ItemRecord key={referRecordJsxes.length} onClick={() => { setCriteria(r.node) }}>
                    <Styles._Span color={'#04ddb9'}>{r.idName}</Styles._Span>
                    {jsx}
                    <Styles._Span color={'#dd0404d6'}>{r.prpName}</Styles._Span>
                </_ItemRecord>);
            });
        }
        return (<_Wrap>
            <_Header>
                <_Filter type="text" value={ls.filter} onChange={(e) => {
                    ls.filter = e.target.value;
                    invalidate();
                }} />
            </_Header>
            <_ScopeListWrap hasRefer={referRecordJsxes.length > 0}>
                {scopeRecordJsxes}
            </_ScopeListWrap>
            {referRecordJsxes.length === 0 ? '' : (
                <_DependListWrap>{referRecordJsxes}</_DependListWrap>
            )}
        </_Wrap>);
    }
};

export default RelationTab;

const _Wrap = styled.div<{
}>`
    display: inline-block;
    position: relative;
    margin: 4px 0 0 4px;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    /* background-color: #1e3d3d; */
`;

const _ScopeListWrap = styled.div<{
    hasRefer: boolean;
}>`
    display: inline-block;
    position: relative;
    width: 100%;
    height: calc(100% - 40px);
    ${props => !props.hasRefer ? '' : css`
        height: calc(70% - 40px);
    `}
    background-color: #32525c;
    overflow-y: auto;
`;
const _DependListWrap = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: 100%;
    height: 30%;
    background-color: #421924;
    overflow-y: auto;
`;

const _Header = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: 100%;
    height: 40px;
    background-color: #000000;
`;

const _Filter = styled.input<{
}>`
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    margin: 4px 0 0 4px;
    background-color: #6e6e6e;
    color: #9cff93;
    box-sizing: border-box;
    border: none;
`;


const _OwnerRecord = styled.div<{
}>`
    display: inline-block;
    position: relative;
    margin: 1px 0 0 2px;
    padding: 2px 0 0 4px;
    /* border: 1px solid #ffffffa9; */
    box-sizing: border-box;
    width: calc(100% - 4px);
    height: 30px;
    /* background-color: #ffffff61; */
    background: linear-gradient(to bottom, #ffffff3c, #ffffff75, #ffffff3c);
    font-size: 18px;
    font-weight: 600;
    color: #00000081;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 8px;
    &:hover {
        background-color: #fcffd4e0;
    }
`;

const _ItemRecord = styled.div<{
}>`
    display: inline-block;
    position: relative;
    margin: 1px 0 0 2px;
    padding: 2px 0 0 4px;
    box-sizing: border-box;
    width: calc(100% - 4px);
    height: 30px;
    /* background-color: #ffffff44; */
    background: linear-gradient(to bottom, #ffffff45, #ffffff16, #ffffff45);
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    &:hover {
        background-color: #ffffff47;
    }
`;