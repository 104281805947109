import styled, { css } from "styled-components";

namespace Styles {

    export const FONT_SMALL = 16;
    export const FONT_MIDIUM = 22;
    export const FONT_LARGE = 38;

    export const _Span = styled.span<{
        color: string;
    }>`
        color: ${props => props.color};
    `;

    export const CSS_LABEL = css`
        display: inline-block;
        font-weight: 600;
        text-align: center;
        color: black;
        box-sizing: border-box;
    `;

    export const CSS_LABEL_SMALL = css`
        ${CSS_LABEL}
        height: 22px;
        font-size: ${FONT_SMALL}px;
        line-height: 18px;
    `;

    export const CSS_LABEL_MIDIUM = css`
        ${CSS_LABEL}
        height: 30px;
        font-size: ${FONT_MIDIUM}px;
        line-height: 26px;
    `;



    export const CSS_LABEL_LARGE = css`
    ${CSS_LABEL}
    height: 50px;
    font-size: ${FONT_LARGE}px;
    line-height: 44px;
`;
    export const CSS_DISABLE = css`
        pointer-events: none;
        opacity: 0.5;
    `;

    export enum DivMarginType {
        NARROW = 4,
        MIDIUM = 6,
        WIDE = 12
    }

    export const _Div = styled.div<{
        margin: DivMarginType;
        color?: string;
        borderColor?: string;
    }>`
        display: inline-block;
        position: relative;
        width: calc(100% - ${props => props.margin * 2}px);
        height: calc(100% - ${props => props.margin * 2}px);
        margin: ${props => props.margin}px 0 0 ${props => props.margin}px;
        ${props => !props.color ? '' : css`
            background-color: ${props.color};
        `};
        ${props => !props.borderColor ? '' : css`
            border: ${1}px solid ${props.borderColor};
            box-sizing: border-box;
        `};
    `;

}
export default Styles;

export const _WrapperFrame = styled.div`
    display: inline-block;
    width: 100%;
    height: 100%;
`;

export const _Object = styled.div`
    display: inline-block;
`;

export const _Record = styled.div`
    display: inline-block;
    width: 100%;
`;

export const _CSS_LABEL_SMALL = css`
    display: inline-block;
    height: 22px;
    font-size: 18px;
    font-weight: 600;
    color: black;
    line-height: 18px;
`;


export const _CSS_LABEL_MIDIUM = css`
    display: inline-block;
    height: 30px;
    font-size: ${Styles.FONT_MIDIUM}px;
    font-weight: 600;
    text-align: center;
    color: black;
    line-height: 24px;
    box-sizing: border-box;
`;

export const _CSS_DISABLE = css`
    opacity: 0.6;
    pointer-events: none;
`;
