import styled, { css } from "styled-components";
import Styles from "../../../../../def/design/styles";
import NodeInnerText from "../editor/ui/tag/nodeInnerText";
import NodeIterate from "../editor/nodeIterate";
import NodeExecute from "../editor/proc/nodeExecute";
import PrefixUtil from "../util/prefixUtil";
import NodeStlarg from "../editor/decrare/nodeStlarg";
import NodeStyle from "../editor/decrare/nodeStyle";
import NodeThen from "../editor/proc/nodeThen";
import ModelUtil from "../util/modelUtil";
import NodeTag from "../editor/ui/tag/nodeTag";
import HtmlUtil from "../../../../util/htmlUtl";
import NodeEntry from "../editor/ui/nodeEntry";
import NodeFetch from "../editor/proc/nodeFetch";
import NodeArrayEff from "../editor/proc/focus/nodeArrayEff";
import NodeArrayDel from "../editor/proc/focus/nodeArrayDel";
import VariableChooser from "../editor/proc/variableChooser";
import NodeArrayCat from "../editor/proc/focus/nodeArrayCat";
import NodeArrayAdd from "../editor/proc/focus/nodeArrayAdd";
import NodeFocus from "../editor/proc/focus/nodeFocus";
import NodeAssign from "../editor/proc/focus/nodeAssign";
import NodeEffect from "../editor/linkage/nodeEffect";
import NodeBlock from "../editor/var/nodeBlock";
import NodeUpdate from "../editor/var/nodeUpdate";
import NodeInvalidate from "../editor/var/func/nodeInvalidate";
import NodePromise from "../editor/var/func/nodePromise";
import NodeNative from "../editor/var/func/nodeNative";
import NodeReturn from "../editor/var/func/nodeReturn";
import NodeFunction from "../editor/var/func/nodeFunction";
import NodeSwitch from "../editor/condition/nodeSwitch";
import NodeAccept from "../editor/condition/nodeAccept";
import NodeCase from "../editor/condition/nodeCase";
import NodeTrigger from "../editor/linkage/nodeTrigger";
import NodePrpclbk from "../editor/var/nodePrpclbkEditor";
import NodeStruct from "../editor/nodeStruct";
import NodeCompuse from "../editor/ui/nodeCompuse";
import NodeCompdef from "../editor/decrare/nodeCompdef";
import NodeApp from "../editor/nodeApp";
import NodeLauncher from "../editor/release/nodeLauncher";
import TreeUtil from "../../../../../common/component/tree/treeUtil";
import NodeLog from "../editor/proc/nodeLog";
import SignatureUtil from "../util/signatureUtil";


namespace TreeViewUtil {
    const COLOR_ROOT = '#a1a1a1';
    const COLOR_VARIABLE = '#d1bda6';
    const COLOR_MNG = '#93b7c0';
    const COLOR_LIST = '#b98989';
    const COLOR_MASTER = '#ac859a';
    const COLOR_DIV = '#cac490';
    const COLOR_ITEM = '#a9b993';
    const COLOR_BLOCK = '#cae5de';
    const COLOR_EXECUTE = '#a4a5c5';
    const COLOR_ITERATE = '#8fd4a6';

    export const getNodeDisplayJsx = (node: TreeUtil.ElementNode) => {
        const wrap = node.data as ModelUtil.WrapElement;
        switch (wrap.type) {
            case 'project': {
                return <_Category backgroundColor={COLOR_ROOT}>Project</_Category>;
            }
            case 'release': {
                return <_Category backgroundColor={COLOR_MNG}>Release</_Category>;
            }
            case 'launchers': {
                return <_Category backgroundColor={COLOR_LIST}>Launchers</_Category>;
            }
            case 'launcher': {
                let jsx = <>{'...'}</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeLauncher.Data;
                    jsx = <><_Span color="#fafafac0">{data.name}</_Span><_Span color="#fc384f">{` @${data.appId}`}</_Span></>;
                }
                return <>
                    <_Category backgroundColor={COLOR_MASTER}>Launcher</_Category>
                    <_EditableValue>{jsx}</_EditableValue>
                </>;
            }
            case 'permissions': {
                return <_Category backgroundColor={COLOR_LIST}>Permissions</_Category>;
            }
            case 'apps': {
                return <_Category backgroundColor={COLOR_LIST}>Apps</_Category>;
            }
            case 'app': {
                let jsx = <>{'...'}</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeApp.Data;
                    jsx = <_Span color="#fafafac0">{data.id}</_Span>;
                }
                return <>
                    <_Category backgroundColor={COLOR_MASTER}>App</_Category>
                    <_EditableValue>{jsx}</_EditableValue>
                </>;
            }
            case 'compdef': {
                let name = '...';
                let partialJsx = <></>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeCompdef.Data;
                    name = data.id;
                    if (data.partialId != undefined) {
                        partialJsx = <_Span color="#c90000ce">{` *${data.partialId}`}</_Span>
                    }
                }
                return <>
                    <_Category backgroundColor={COLOR_MASTER}>
                        Component
                        {partialJsx}
                    </_Category>
                    <_EditableValue>{name}</_EditableValue>
                </>;
            }
            case 'compuse': {
                let dispJsx = <>...</>;
                let tagJsx = <></>;
                let color = COLOR_ITEM;
                if (wrap.data != null) {
                    const data = wrap.data as NodeCompuse.Data;
                    dispJsx = <_Span color="#ffffff7f">{`(${data.props.join(', ')})`}</_Span>;

                    tagJsx = <>Component{' '}<_Span color="#484848">{`<${data.compId}>`}</_Span></>;
                    // 子要素を持つ場合色を変える
                    if (data.elements != undefined) color = COLOR_DIV;
                }
                return <>
                    <_Category backgroundColor={color}>{tagJsx}</_Category>
                    <_EditableValue>{dispJsx}</_EditableValue>
                </>;
            }
            case 'child': {
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Child</_Category>
                </>;
            }
            // case 'authority': {
            //     return <_Category backgroundColor={COLOR_MNG}>Authority</_Category>;
            // }
            // case 'regulation': {
            //     return <_Category backgroundColor={COLOR_MNG}>Regulation</_Category>;
            // }
            // case 'users': {
            //     return <_Category backgroundColor={COLOR_LIST}>Users</_Category>;
            // }
            // case 'groups': {
            //     return <_Category backgroundColor={COLOR_LIST}>Groups</_Category>;
            // }
            // case 'linkage': {
            //     return <_Category backgroundColor={COLOR_MNG}>Linkage</_Category>;
            // }
            case 'common': {
                return <_Category backgroundColor={COLOR_MNG}>Common</_Category>;
            }
            case 'declares': {
                return <_Category backgroundColor={COLOR_MNG}>Declares</_Category>;
            }
            case 'store': {
                return <_Category backgroundColor={COLOR_MNG}>Store</_Category>;
            }
            case 'initial': {
                return <_Category backgroundColor={COLOR_MNG}>Initial</_Category>;
            }
            case 'fetches': {
                return <_Category backgroundColor={COLOR_LIST}>Fetches</_Category>;
            }
            case 'args': {
                return <_Category backgroundColor={COLOR_LIST}>Arguments</_Category>;
            }
            case 'props': {
                return <_Category backgroundColor={COLOR_LIST}>Props</_Category>;
            }
            case 'comps': {
                return <_Category backgroundColor={COLOR_LIST}>Components</_Category>;
            }
            case 'tags': {
                return <_Category backgroundColor={COLOR_LIST}>Tags</_Category>;
            }
            case 'structs': {
                return <_Category backgroundColor={COLOR_LIST}>Structs</_Category>;
            }
            case 'struct': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeStruct.Data;
                    infoJsx = <_EditableValue>{data.id}</_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_MASTER}>Struct</_Category>
                    {infoJsx}
                </>;
            }
            case 'field': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as ModelUtil.NodeModelField;
                    const array = '[]'.repeat(data.array);
                    const [type, color] = data.dataType !== 'struct' ? [data.dataType, "#fddf35"] : [data.structId, "#ff5e5e"];
                    const typeJsx = <_Span color={color}>@{type}{array}</_Span>;
                    infoJsx = <_EditableValue>{data.id}: {typeJsx}</_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Field</_Category>
                    {infoJsx}
                </>;
            }
            case 'proc': {
                return <_Category backgroundColor={COLOR_MNG}>Procedure</_Category>;
            }
            case 'retention': {
                return <_Category backgroundColor={COLOR_MNG}>Retention</_Category>;
            }
            case 'states': {
                return <_Category backgroundColor={COLOR_LIST}>States</_Category>;
            }
            case 'state': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    // const data = wrap.data as ModelUtil.NodeModelField;
                    // const array = '[]'.repeat(data.array);
                    // const [type, color] = data.dataType !== 'struct' ? [data.dataType, "#fddf35"] : [data.structId, "#ff5e5e"];
                    // const typeJsx = <_Span color={color}>@{type}{array}</_Span>;
                    // infoJsx = <_EditableValue>{`__${PrefixUtil.STATE}.`}<_Span color="#6ffc38">{data.id}</_Span>{`__`}: {typeJsx}</_EditableValue>;
                    infoJsx = <_EditableValue>{SignatureUtil.getStateName(wrap.data)}</_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_VARIABLE}>State</_Category>
                    {infoJsx}
                </>;
            }
            case 'arg': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as ModelUtil.NodeModelField;
                    const array = '[]'.repeat(data.array);
                    const [type, color] = data.dataType !== 'struct' ? [data.dataType, "#fddf35"] : [data.structId, "#ff5e5e"];
                    const typeJsx = <_Span color={color}>@{type}{array}</_Span>;
                    infoJsx = <_EditableValue>{`__${PrefixUtil.VARIABLE}.`}<_Span color="#6ffc38">{data.id}</_Span>{`__`}: {typeJsx}</_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Argument</_Category>
                    {infoJsx}
                </>;
            }
            case 'prpfld': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as ModelUtil.NodeModelField;
                    const array = '[]'.repeat(data.array);
                    const [type, color] = data.dataType !== 'struct' ? [data.dataType, "#fddf35"] : [data.structId, "#ff5e5e"];
                    const typeJsx = <_Span color={color}>@{type}{array}</_Span>;
                    infoJsx = <_EditableValue>{`__${PrefixUtil.VARIABLE}.`}<_Span color="#6ffc38">{data.id}</_Span>{`__`}: {typeJsx}</_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Field</_Category>
                    {infoJsx}
                </>;
            }
            case 'prpclbk': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodePrpclbk.Data;
                    const typeJsx = <_Span color={"#fd8f35"}>@callback</_Span>;
                    infoJsx = <_EditableValue>{`__${PrefixUtil.FUNCTION}.`}<_Span color="#6ffc38">{data.id}</_Span>{`__`}: {typeJsx}</_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_MNG}>Callback</_Category>
                    {infoJsx}
                </>;
            }
            case 'trigger': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeTrigger.Data;
                    infoJsx = <_EditableValue><_Span color="#ff812d">{data.method}</_Span></_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_EXECUTE}>Trigger</_Category>
                    {infoJsx}
                </>;
            }
            case 'accept': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeAccept.Data;
                    infoJsx = <_EditableValue><_Span color="#fd3535">{data.condition}</_Span></_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_EXECUTE}>Accept</_Category>
                    {infoJsx}
                </>;
            }
            case 'case': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeCase.Data;
                    let detail = '';
                    if (data.condition != undefined) detail = data.condition;
                    else if (data.target != undefined) detail = data.target;
                    let append = <></>;
                    switch (data.method) {
                        case 'bool': {
                            append = <>: <_Span color="#fd3535">[{data.condition}]</_Span></>;
                        } break;
                        // case 'range':
                        case 'switch': {
                            append = <>: <_Span color="#fd3535">[{data.target}]</_Span></>;
                        } break;
                    }
                    infoJsx = <_EditableValue><_Span color="#c8c9ff">@{data.method}{append}</_Span ></_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_MNG}>Case</_Category>
                    {infoJsx}
                </>;
            }
            case 'bool': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as ModelUtil.NodeBoolData;
                    infoJsx = <_EditableValue><_Span color="#fffc45">{data.bool ? 'True' : 'False'}</_Span></_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_EXECUTE}>bool</_Category>
                    {infoJsx}
                </>;
            }
            case 'when': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeAccept.Data;
                    infoJsx = <_EditableValue><_Span color="#fd3535">[{data.condition}]</_Span></_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_EXECUTE}>Condition</_Category>
                    {infoJsx}
                </>;
            }
            case 'switch': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeSwitch.Data;
                    infoJsx = <_EditableValue><_Span color="#fd3535">{data.fml}</_Span></_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_EXECUTE}>Pattern</_Category>
                    {infoJsx}
                </>;
            }
            // case 'range': {
            //     let infoJsx = <_EditableValue>...</_EditableValue>;
            //     if (wrap.data != null) {
            //         const data = wrap.data as NodeRange.Data;
            //         infoJsx = <_EditableValue><_Span color="#fd3535">{data.fml}</_Span></_EditableValue>;
            //     }
            //     return <>
            //         <_Category backgroundColor={COLOR_EXECUTE}>Break Value</_Category>
            //         {infoJsx}
            //     </>;
            // }
            case 'styles': {
                return <_Category backgroundColor={COLOR_LIST}>Styles</_Category>;
            }
            case 'funcs': {
                return <_Category backgroundColor={COLOR_LIST}>Functions</_Category>;
            }
            // case 'consts': {
            //     return <_Category backgroundColor={COLOR_LIST}>Constants</_Category>;
            // }
            case 'elements': {
                return <_Category backgroundColor={COLOR_LIST}>Elements</_Category>;
            }
            case 'func': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    // const data = wrap.data as NodeFunction.Data;
                    // const name = <_Span color="#0077ff">{data.id}</_Span>;
                    // const getRetJsx = () => {
                    //     const ret = data.ret;
                    //     if (ret == undefined) return <_Span color='#cccccc'>void</_Span>;
                    //     return <><_Span color='#e60000'>{ModelUtil.getField(ret)}</_Span></>;
                    // }
                    // infoJsx = <_EditableValue>{name}{' => '}{getRetJsx()}</_EditableValue>;
                    infoJsx = <_EditableValue>{SignatureUtil.getFunctionName(wrap.data)}</_EditableValue>;
                }
                // const categoryName = wrap.type === 'closure' ? 'Closure' : 'Dispatcher';
                // return <>
                //     <_Category backgroundColor={COLOR_MASTER}>{categoryName}</_Category>
                //     {infoJsx}
                // </>;
                return <>
                    <_Category backgroundColor={COLOR_MASTER}>Function</_Category>
                    {infoJsx}
                </>;
            }
            case 'log': {
                let infoJsx = <>...</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeLog.Data;
                    const methodJsx = <_Span color="#ffffff">{data.method}</_Span>;
                    const fmlJsx = <>(<_Span color="#f32020">{data.fml}</_Span>)</>;
                    infoJsx = <>{methodJsx}{fmlJsx}</>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Log</_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            case 'return': {
                let infoJsx = <>...</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeReturn.Data;
                    infoJsx = <_Span color="#d43333">{`(${data.fml})`}</_Span>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Return</_Category>
                    {wrap.data != null && wrap.data.fml == undefined ? '' :
                        <_EditableValue>{infoJsx}</_EditableValue>
                    }
                </>;
            }
            case 'native': {
                let infoJsx = <>...</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeNative.Data;
                    infoJsx = <>
                        <_Span color="#b3afad">{`{${data.comment}}`}</_Span>
                    </>;
                }

                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Native</_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            case 'promise': {
                let infoJsx = <>...</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodePromise.Data;
                    infoJsx = <>
                        <_Span color="#b3afad">{`{${data.comment}}`}</_Span>
                    </>;
                }

                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Promise</_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            case 'invalidate': {
                let infoJsx = <>...</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeInvalidate.Data;
                    infoJsx = <>
                        <_Span color="#e4eb5d">{`${data.partial ?? '*'}`}</_Span>
                    </>;
                }

                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Invalidate</_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            case 'continue': {
                return <_Category backgroundColor={COLOR_ITEM}>Continue</_Category>;
            }
            case 'break': {
                return <_Category backgroundColor={COLOR_ITEM}>Break</_Category>;
            }
            case 'update': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeUpdate.Data;
                    const target = <_Span color="#fd5d35">{data.rootId}</_Span>;
                    const assign = <_Span color="#fd5d35">{data.assign}</_Span>;
                    const typeJsx = <_Span color="#e2e2e2">{data.referProps == undefined ? 'Direct' : 'Refer'}</_Span>;
                    const getDetail = () => {
                        switch (data.method) {
                            case 'assign': return <>={assign}@{typeJsx}</>;
                            case 'push': return <>.push[{assign}@{typeJsx}]</>;
                            case 'remove': {
                                const index = <_Span color="#8efc8b">{data.index}</_Span>;
                                return <>.remove({index})</>;
                            }
                        }
                    }
                    const update = <_Span color="#fffffd8f">{data.target}[{target}]{getDetail()}</_Span>;
                    const methodJsx = <_Span color="#2e6bf0">#{data.method}</_Span>;
                    infoJsx = <>{methodJsx}{'->'}{update}</>
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Updater</_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            case 'block': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeBlock.Data;
                    infoJsx = <_EditableValue><_Span color="#b3afad">{`{${data.comment}}`}</_Span></_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_BLOCK}>Block</_Category>
                    {infoJsx}
                </>;
            }
            case 'effect': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeEffect.Data;
                    infoJsx = <_EditableValue><_Span color="#b3afad">{`{${data.comment}}`}</_Span></_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Effect</_Category>
                    {infoJsx}
                </>;
            }
            case 'focus': {
                let infoJsx = <>...</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeFocus.Data;
                    const targetJsx = <_Span color="#f0a22e">#{data.target}</_Span>;
                    const addressJsx = <>[<_Span color="#a9dfb1">{VariableChooser.getAddressSimple(data)}</_Span>]</>;
                    infoJsx = <>{targetJsx}{addressJsx}</>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Focus</_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            case 'assign': {
                let infoJsx = <>...</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeAssign.Data;
                    const objVal = data.refer;
                    let detailJsx = <></>;
                    let instanceStr = '';
                    switch (data.assignType) {
                        case 'refer': {
                            if (objVal != undefined) {
                                const targetJsx = <_Span color="#f0a22e">#{objVal.target}</_Span>;
                                const addressJsx = <>[<_Span color="#a9dfb1">{VariableChooser.getAddressSimple(objVal)}</_Span>]</>;
                                detailJsx = <>{targetJsx}{addressJsx}</>;
                                instanceStr = `${objVal.cloneType ?? 'shallow'}`;
                            }
                        } break;
                        case 'direct': {
                            if (data.direct != undefined) {
                                detailJsx = <>direct [<_Span color="#a9dfb1">{data.direct}</_Span>]</>;
                            }
                        } break;
                        case 'formula': {
                            if (data.formula != undefined) {
                                detailJsx = <>return (<_Span color="#de1e1e">{data.formula}</_Span>)</>;
                            }
                        } break;
                        case 'initial': {
                            detailJsx = <_Span color="#fdfdfd">Initial</_Span>;
                        } break;
                    }
                    infoJsx = <><_Span color="#ffffff">{`←${instanceStr} `}</_Span>{detailJsx}</>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Assign</_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            // case 'asgnfml': {
            //     let infoJsx = <>...</>;
            //     if (wrap.data != null) {
            //         const data = wrap.data as NodeAssignFormula.Data;
            //         infoJsx = <_Span color="#e2e2e2">{`←[${data.fml}]`}</_Span>;
            //     }
            //     return <>
            //         <_Category backgroundColor={COLOR_ITEM}>Assign-Formula</_Category>
            //         <_EditableValue>{infoJsx}</_EditableValue>
            //     </>;
            // }
            case 'asnmtch': {
                let infoJsx = <>...</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeFocus.Data;
                    const targetJsx = <_Span color="#f0a22e">#{data.target}</_Span>;
                    const addressJsx = <>[<_Span color="#a9dfb1">{VariableChooser.getAddressSimple(data)}</_Span>]</>;
                    infoJsx = <>{targetJsx}{addressJsx}</>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Assign-Find</_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            case 'arradd': {
                let infoJsx = <>...</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeArrayAdd.Data;
                    const objVal = data.objVal;
                    const directVal = data.fmlVal;
                    let detailJsx = <></>;
                    const indexJsx = <_Span color="#f02e2e">+[{data.index ?? 'last'}]:</_Span>;
                    if (objVal != undefined) {
                        const targetJsx = <_Span color="#f0a22e">#{objVal.target}</_Span>;
                        const addressJsx = <>[<_Span color="#a9dfb1">{VariableChooser.getAddressSimple(objVal)}</_Span>]</>;
                        detailJsx = <>{targetJsx}{addressJsx}</>;
                    } else if (directVal != undefined) {
                        detailJsx = <>[<_Span color="#a9dfb1">{directVal}</_Span>]</>;
                    }
                    infoJsx = <>{indexJsx}<_Span color="#ffffff"></_Span>{detailJsx}</>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Array-Add</_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            case 'arrcat': {
                let infoJsx = <>...</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeArrayCat.Data;
                    const objVal = data.objVal;
                    let detailJsx = <></>;
                    const indexJsx = <_Span color="#f02e2e">+[{data.index ?? 'last'}]:</_Span>;
                    const targetJsx = <_Span color="#f0a22e">#{objVal.target}</_Span>;
                    const addressJsx = <>[<_Span color="#a9dfb1">{VariableChooser.getAddressSimple(objVal)}</_Span>]</>;
                    detailJsx = <>{targetJsx}{addressJsx}</>;
                    infoJsx = <>{indexJsx}<_Span color="#ffffff"></_Span>{detailJsx}</>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Array-Cat</_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            case 'arrdel': {
                let infoJsx = <>...</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeArrayDel.Data;
                    const indexJsx = <_Span color="#f02e2e">-[{data.index}]: </_Span>;
                    infoJsx = <>{indexJsx}{data.delCnt}</>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Array-Del</_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            case 'arreff': {
                let infoJsx = <>...</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeArrayEff.Data;
                    const methodJsx = <_Span color="#f02e2e">{data.method}</_Span>;
                    infoJsx = <>{methodJsx}: </>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Array-Eff</_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            case 'fetch': {
                let url = '...';
                if (wrap.data != null) {
                    const data = wrap.data as NodeFetch.Data;
                    url = data.url;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Fetch</_Category>
                    <_EditableValue><_Span color="#d8fd35">{url}</_Span></_EditableValue>
                </>;
            }
            case 'entry': {
                const data = wrap.data as NodeEntry.Data;
                const entryJsx = <><_Span color="#f51d1d">component: </_Span>[{data.compId ?? '-'}]</>;
                return <>
                    <_Category backgroundColor={COLOR_MNG}>Entry</_Category>
                    <_EditableValue>{entryJsx}</_EditableValue>
                </>;
            }
            // case 'tagdef': {
            //     const data = wrap.data as NodeTagdef.Data;
            //     let infoJsx = <_EditableValue>...</_EditableValue>;
            //     if (wrap.data != null) {
            //         infoJsx = <_EditableValue>{data.id}</_EditableValue>;
            //     }
            //     return <>
            //         <_Category backgroundColor={COLOR_MASTER}>Tag</_Category>
            //         {infoJsx}
            //     </>;
            // }
            case 'tag': {
                let infoJsx = <>...</>;
                let element = '?';
                let isVoidElement = false;
                let partialJsx = <></>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeTag.Data;
                    isVoidElement = HtmlUtil.VoidElements.includes(data.element);
                    element = data.element;
                    if (data.partialId != undefined) {
                        partialJsx = <_Span color="#c90000ce">{` *${data.partialId}`}</_Span>
                    }
                    infoJsx = <>
                        <_Span color="#b3afad">{`{${data.comment}}`}</_Span>{NodeStyle.getStyleRefsJsx('Styles', data.styles)}
                    </>;
                }
                return <>
                    <_Category backgroundColor={!isVoidElement ? COLOR_DIV : COLOR_ITEM}>
                        Tag<_Span color="#484848">{` <${element}>`}</_Span>
                        {partialJsx}
                    </_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            case 'child': {
                return <_Category backgroundColor={COLOR_ITEM}>Child</_Category>;
            }
            case 'variable': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    // const data = wrap.data as ModelUtil.NodeModelField;
                    // const array = '[]'.repeat(data.array);
                    // const [type, color] = data.dataType !== 'struct' ? [data.dataType, "#fddf35"] : [data.structId, "#ff5e5e"];
                    // const typeJsx = <_Span color={color}>@{type}{array}</_Span>;
                    // infoJsx = <_EditableValue>{`__${PrefixUtil.VARIABLE}.`}<_Span color="#6ffc38">{data.id}</_Span>{`__`}: {typeJsx}</_EditableValue>;
                    infoJsx = <_EditableValue>{SignatureUtil.getVariableName(wrap.data)}</_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_VARIABLE}>Variable</_Category>
                    {infoJsx}
                </>;
            }
            case 'then': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as ModelUtil.NodeModelField;
                    const array = '[]'.repeat(data.array);
                    const [type, color] = data.dataType !== 'struct' ? [data.dataType, "#fddf35"] : [data.structId, "#ff5e5e"];
                    const typeJsx = <_Span color={color}>@{type}{array}</_Span>;
                    infoJsx = <_EditableValue>{`\${${PrefixUtil.VARIABLE}.`}<_Span color="#6ffc38">{data.id}</_Span>{`}`}: {typeJsx}</_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_MNG}>Then</_Category>
                    {infoJsx}
                </>;
            }
            case 'catch': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeThen.Data;
                    const [type, color] = ['string', "#fddf35"];
                    const typeJsx = <_Span color={color}>@{type}</_Span>;
                    infoJsx = <_EditableValue>{`\${c.`}<_Span color="#6ffc38">{data.id}</_Span>{`}`}: {typeJsx}</_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_MNG}>Catch</_Category>
                    {infoJsx}
                </>;
            }
            case 'style': {
                let infoJsx = <>...</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeStyle.Data;
                    // let referJsx = <></>;
                    // if (data.inherits.length > 0) {
                    //     const refers = data.inherits.map(d => d.refId).join(', ');
                    //     referJsx = <_Span color="#d1dfa0">inherit[{refers}]</_Span>;
                    // }
                    // infoJsx = <>
                    //     <_Span color="#b3afad">{`@${data.id}`}</_Span>{NodeStyle.getStyleRefsJsx('Inherits', data.inherits)}
                    // </>;
                    infoJsx = <>{SignatureUtil.getStyleName(data)}</>;
                }
                return <>
                    <_Category backgroundColor={COLOR_MASTER}>Style</_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            case 'stlarg': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeStlarg.Data;
                    const defValJsx = data.defVal == undefined ? '' : <>: <_Span color={'#ffe656'}>[{data.defVal}]</_Span></>;
                    infoJsx = <_EditableValue>{`__${PrefixUtil.STYLE_ARGUMENT}.`}<_Span color="#6ffc38">{data.id}</_Span>{`__`}{defValJsx}</_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Argument</_Category>
                    {infoJsx}
                </>;
            }
            case 'execute': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeExecute.Data;
                    const eventId = <_Span color="#e2e189">{data.eventId}</_Span>;
                    const args = <_Span color="#90e4f396">{data.args.join(', ')}</_Span>;
                    infoJsx = <_EditableValue>[{eventId}] ({args})</_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Execute</_Category>
                    {infoJsx}
                </>;
            }
            case 'iterate': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeIterate.Data;
                    infoJsx = <_EditableValue>{data.id}: <_Span color="#fd3535">[{data.itrCntFml}]</_Span></_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITERATE}>Iterate</_Category>
                    {infoJsx}
                </>;
            }
            case 'text': {
                let infoJsx = <>...</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeInnerText.Data;
                    infoJsx = (() => {
                        if (data.direct != undefined) return (
                            <_Span color="#a3e7e4">{`{${data.direct}}`}</_Span>
                        );
                        else if (data.fml != undefined) return (
                            <_Span color="#d43333">{`return (${data.fml})`}</_Span>
                        );
                        else throw new Error('NodeInnerText.Dataのdataが不正。');
                    })();
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Inner-Text</_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            // case 'table-mng': {
            //     const data = wrap.data as NodeTableMng;
            //     let request = 'none';
            //     if (data.requestId != undefined) request = data.requestId;
            //     return <>
            //         <_Category backgroundColor={COLOR_MNG}>Table Manager</_Category>
            //         <_EditableValue>{request}</_EditableValue>
            //     </>;
            // }
            // case 'form-mng': {
            //     const data = wrap.data as NodeFormMng;
            //     let request = 'none';
            //     if (data.requestId != undefined) request = data.requestId;
            //     const detailJsx = <span>
            //         <_Span color="#ffe89ba6">request:&nbsp;</_Span>
            //         <_Span color={data.requestId == undefined ? "#ffffffc0" : "#ff1818c0"}>{request}</_Span>
            //     </span>;
            //     return <>
            //         <_Category backgroundColor={COLOR_MNG}>Form Manager</_Category>
            //         <_EditableValue>{detailJsx}</_EditableValue>

            //     </>;
            // }
        }
        return <_Category backgroundColor={'#ffffff'}>XXXXX</_Category>;
    }
}

export default TreeViewUtil;


const _Category = styled.div<{
    backgroundColor: string;
    isDisable?: boolean;
}>`
    ${Styles.CSS_LABEL_MIDIUM}
    display: inline-block;
    position: relative;
    background-color: ${props => props.backgroundColor};
    /* width: 100%; */
    ${props => !(props.isDisable ?? false) ? '' : css`
        text-decoration: line-through;
        color: #00054dac;
    `}
    border-radius: 4px 0 0 4px;
    margin: 5px 0 0 0;
    padding: 0 10px;
    border: solid 1px #000000d2;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .5);
`;
const _Detail = styled.span`
    color: #000b69bd;
`;

const _EditableValue = styled.div`
    ${Styles.CSS_LABEL_MIDIUM}
    display: inline-block;
    position: relative;
    font-style: italic;
    background-color: #0c0c0c;
    color: #ebebeb;
    border-radius: 0 4px 4px 0;
    /* width: 100%; */
    margin: 5px 0 0 0;
    padding: 0 10px;
`;
const _ColorTip = styled.div<{
    color: string;
}>`
    display: inline-block;
    width: 10px;
    height: calc(100% - 8px);
    margin: 4px 4px 0 0;
    background-color: ${props => props.color};
    border-radius: 2px;
`;
const _Span = styled.span<{
    color: string;
}>`
    color: ${props => props.color};
`;
const _ValueType = styled.span`
    color: #fff9d6b9;
`;
const _Signature = styled.span`
    color: #ffffff78;
`;
const _Key = styled.span`
    color: #ff2d2d;
`;